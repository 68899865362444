import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Navbar, Nav,Button} from 'react-bootstrap';
import BrandColor from '../../assets/images/RapidWorkLogo.png';
import BrandnonColor from '../../assets/images/Transparent.png';

export default (props) => {
  const { toggleMenu, locale, handleChangeLanguage, handleClickTopic, handleToggleOpenMenu, burgerMark } = props;

  const [isScroll, setIsScroll] = useState(false);

  const onScroll = () => {
    if (window.scrollY >= 1) {
      setIsScroll(true);
    }
    else {
      setIsScroll(false);
    }
  };

  window.addEventListener('scroll', onScroll);

  console.log('burgerMark ::', burgerMark)

  return (
    <Navbar className={`navbar-customize navbar-customize-lang-${locale} ${isScroll ? 'navbar-customize-sticky' : 'navbar-customize-fixed'} ${toggleMenu ? 'navbar-customize-sticky-fixd-open' : null}`} sticky='top' expand='lg' collapseOnSelect expanded={toggleMenu ? 'expanded' : false}>
      <div className={`${isScroll ? 'navbar-background-div' : 'hide'}`}></div>
      <Container fluid='xl'>
        <Navbar.Brand href="#"><img className={isScroll ? 'navbar-brand' : 'navbar-brand'} src={isScroll || toggleMenu ? BrandColor : BrandnonColor} width={320} height={45} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggleOpenMenu} className={toggleMenu || isScroll ? 'navbar-toggle-color-black' : 'navbar-toggle-color'} />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className='me-auto'></Nav>
          {
            toggleMenu ? <Nav style={{ textAlign: 'center', marginTop: '42px' }} className='navbar-hidden-burger'>
              <Nav.Link onClick={() => handleClickTopic('home-container')} style={burgerMark === 'home' ? { color: '#111111', fontWeight: 'bold' } : { color: '#bcbdbf' }}><FormattedMessage id='navBarHome' /></Nav.Link>
              <Nav.Link onClick={() => handleClickTopic('features-container')} style={burgerMark === 'fetures' ? { color: '#111111', marginTop: '10px', fontWeight: 'bold' } : { color: '#bcbdbf', marginTop: '10px' }}><FormattedMessage id='navBarFeatures' /></Nav.Link>
              {/* <Nav.Link href='#' style={{ color: 'black' }}><FormattedMessage id='navBarPricing' /></Nav.Link> */}
              <Nav.Link onClick={() => handleClickTopic('contact-us-container1')} style={burgerMark === 'contact' ? { color: '#111111', marginTop: '10px', fontWeight: 'bold' } : { color: '#bcbdbf', marginTop: '10px' }}><FormattedMessage id='navBarContactUS' /></Nav.Link>
              <div className='navbar-lang-align'>
                <Nav.Link style={{ color: '#e10000', marginRight: '18px' }} className={`navbar-customize-lang-fixed-locale ${locale !== 'en' ? 'navbar-customize-lang-fixed-locale-none' : ''}`} onClick={() => handleChangeLanguage('en')}><FormattedMessage id='navBarLanguageEN' /></Nav.Link>
                <Navbar.Text style={{ color: '#bcbdbf' }}>|</Navbar.Text>
                <Nav.Link style={{ color: '#e10000', marginLeft: '18px' }} className={`navbar-customize-lang-fixed-locale ${locale !== 'th' ? 'navbar-customize-lang-fixed-locale-none' : ''}`} onClick={() => handleChangeLanguage('th')}><FormattedMessage id='navBarLanguageTH' /></Nav.Link>
              </div>
            </Nav> : null
          }
          <Nav className='navbar-hidden-row'>
           <Nav.Link style={burgerMark === 'home' ? { color: '#e10000', fontWeight: 'bold',marginLeft: '21px' } : { color: '#4a4a4a',marginLeft: '21px' }} onClick={() => handleClickTopic('home-container')}><FormattedMessage id='navBarHome' /></Nav.Link>
            <Nav.Link style={burgerMark === 'fetures' ? { color: '#e10000', fontWeight: 'bold',marginLeft: '21px' } : { color: '#4a4a4a',marginLeft: '21px' }} onClick={() => handleClickTopic('features-container')}><FormattedMessage id='navBarFeatures' /></Nav.Link>
            {/* <Nav.Link href='#'><FormattedMessage id='navBarPricing' /></Nav.Link> */}
            <Nav.Link style={burgerMark === 'contact' ? { color: '#e10000', fontWeight: 'bold',marginLeft: '21px',marginRight: '21px' } : { color: '#4a4a4a',marginLeft: '21px',marginRight: '21px' }} onClick={() => handleClickTopic('contact-us-container')}><FormattedMessage id='navBarContactUS' /></Nav.Link>
            <Nav.Link className={`navbar-customize-lang-fixed-locale ${locale !== 'en' ? 'navbar-customize-lang-fixed-locale-none' : ''}`} onClick={() => handleChangeLanguage('en')}><FormattedMessage id='navBarLanguageEN' /></Nav.Link>
            <Navbar.Text className='navbar-customize-lang-fixed-locale-bar'>|</Navbar.Text>
            <Nav.Link className={`navbar-customize-lang-fixed-locale ${locale !== 'th' ? 'navbar-customize-lang-fixed-locale-none' : ''}`} onClick={() => handleChangeLanguage('th')}><FormattedMessage id='navBarLanguageTH' /></Nav.Link>
          <hr />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar >
  )
}