import React from 'react';
import TopMenuVav from './top-menu-nav';
import { PageSettings } from '../../config/page-settings';

export default () => {


  return (
    <PageSettings.Consumer>
      {({ locale, toggleMenu, handleChangeLanguage, handleClickTopic, handleToggleOpenMenu, handleToggleCloseMenu, burgerMark }) => (
        <>
          <TopMenuVav
            locale={locale}
            toggleMenu={toggleMenu}
            handleChangeLanguage={handleChangeLanguage}
            handleClickTopic={handleClickTopic}
            handleToggleOpenMenu={handleToggleOpenMenu}
            handleToggleCloseMenu={handleToggleCloseMenu}
            burgerMark={burgerMark}
          />
        </>
      )}
    </PageSettings.Consumer>
  )
}