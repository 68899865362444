import React, { useState, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Container, Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { notification } from 'antd';
import { sendContact } from '../../contollers/contact-controller';
import '../../../re-design/assets/css/send-email-mobile.css'

export default (props) => {
  const intl = useIntl();
  const modalProps = { ...props };
  _.unset(modalProps, ['locale']);
  _.unset(modalProps, ['contactTopicEn']);
  _.unset(modalProps, ['contactTopicTh']);
  _.unset(modalProps, ['handleShowPolicyModal']);
  _.unset(modalProps, ['policyStatus']);
  const { locale, contactTopicEn, contactTopicTh, handleShowPolicyModal, policyStatus, show, onHide } = props;
  const [validated, setValidated] = useState(false);
  const [topicClass, setTopicClass] = useState();
  const checkboxRef = useRef();

  useEffect(() => {
    setValidated(false);
  }, [show]);

  useEffect(() => {
    if (checkboxRef.current && policyStatus) {
      checkboxRef.current.checked = policyStatus;
      console.log(checkboxRef.current.checked);
    }
  }, [policyStatus]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      // event.preventDefault();
      // event.stopPropagation();
    }
    else {
      const data = {
        fullname: form['fullname'].value,
        email: form['email'].value,
        phone: form['phone'].value,
        companyName: form['companyName'].value,
        companyWebsite: form['companyWebsite'].value,
        status: form['topic'].value,
        description: form['description'].value,
        lang: _.toUpper(locale)
      };
      const result = await sendContact(data);
      notification[result.success ? 'success' : 'error']({
        message: result.message,
        style: {
          fontFamily: locale === 'en' ? 'SFThonburi-Regular' : 'IBMPlexSansThai-Regular',
          color: '#65676b'
        }
      });
      onHide();
      return;
    }




    setValidated(true);
  };

  const handleChangTopic = (e) => {
    setTopicClass(e.target.value);
  }

  return (
    <Modal
      {...modalProps}
      className='send-email-mobile-modal'
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
          <h1 className={`contact-form-header-${locale}`}><FormattedMessage id='contactFormHeader' /></h1>
        <Row>
          <Col lg={6} style={{ justifyContent: 'center', display: 'flex' }}>
            <div className='div-send-email-icon' />
          </Col>
        </Row>
        <Container className='send-email-mobile-container' fluid='xl'>
          {show ?
            <Form id='form-contact-us' noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col lg={6}>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='fullname'>
                      <Form.Control
                        className={`contact-form-input-${locale}`}
                        required
                        type='text'
                        placeholder={intl.formatMessage({ id: 'contactInputFullName' })}
                      />
                      <Form.Control.Feedback className={`contact-invalid-${locale}`} type='invalid'><FormattedMessage id='contactInputValidFullName' /></Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='email'>
                      <Form.Control
                        className={`contact-form-input-${locale}`}
                        required
                        type='email'
                        placeholder={intl.formatMessage({ id: 'contactInputEmail' })}
                      />
                      <Form.Control.Feedback className={`contact-invalid-${locale}`} type='invalid'><FormattedMessage id='contactInputValidEmail' /></Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='phone'>
                      <Form.Control
                        className={`contact-form-input-${locale}`}
                        required
                        type='number'
                        placeholder={intl.formatMessage({ id: 'contactInputPhone' })}
                      />
                      <Form.Control.Feedback className={`contact-invalid-${locale}`} type='invalid'><FormattedMessage id='contactInputValidPhone' /></Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='companyName'>
                      <Form.Control
                        className={`contact-form-input-${locale}`}
                        type='text'
                        placeholder={intl.formatMessage({ id: 'contactInputCompanyName' })}
                      />
                    </Form.Group>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='companyWebsite'>
                      <Form.Control
                        className={`contact-form-input-${locale}`}
                        type='text'
                        placeholder={intl.formatMessage({ id: 'contactInputCompanyWebSite' })}
                      />
                    </Form.Group>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='topic'>
                      <Form.Select
                        required
                        className={`contact-form-input-${locale} ${topicClass ? '' : 'first-value-select'}`}
                        aria-label='Default select example'
                        defaultValue=''
                        onChange={handleChangTopic}>
                        <option value='' disabled hidden>{intl.formatMessage({ id: 'contactInputTopic' })}</option>
                        {locale === 'en' ?
                          contactTopicEn.map((item) => <option key={item.text} value={item.text}>{item.text}</option>)
                          :
                          contactTopicTh.map((item) => <option key={item.text} value={item.text}>{item.text}</option>)
                        }
                      </Form.Select>
                      <Form.Control.Feedback className={`contact-invalid-${locale}`} type='invalid'><FormattedMessage id='contactInputValidTopic' /></Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='description'>
                      <Form.Control as='textarea' className={`contact-form-input-${locale}`} placeholder={intl.formatMessage({ id: 'contactInputDescription' })} rows={3} />
                    </Form.Group>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className='mb-3'>
                    <Form.Group md='12'>
                      <Form.Check
                        ref={checkboxRef}
                        required
                        label={<span className={`contact-policy-${locale}`}>&nbsp;<FormattedMessage id='contactCheckedPolicy-1' /><a className='contact-policy' onClick={handleShowPolicyModal}><FormattedMessage id='contactCheckedPolicy-2' /></a><FormattedMessage id='contactCheckedPolicy-3' /></span>}
                        feedback={<span className={`contact-invalid-${locale}`}>{intl.formatMessage({ id: 'contactInputValidPolicy' })}</span>}
                        feedbackType='invalid'
                      />
                    </Form.Group>
                  </Row>
                </Col>
              </Row>
              <Row className='mrg-t-35'>
                <Col className='t-a-c' lg={12}>
                  <Button className={`contact-submit contact-submit-${locale}`} type='submit' style={{fontSize:'16px'}}><FontAwesomeIcon icon={faPaperPlane} />&nbsp;&nbsp; <FormattedMessage id='contactBtnSendContact' /></Button>
                </Col>
              </Row>
            </Form> : null}
        </Container>
      </Modal.Body>
    </Modal >
  );
}