import React from 'react';
import FooterMenuNav from './footer-menu-nav';
import { PageSettings } from '../../config/page-settings';

export default () => {


  return (
    <PageSettings.Consumer>
      {({ locale, handleClickTopic, handleChangeLanguage, handleShowCookieModal, handleShowPolicyMobileModal, handleShowPolicyModal, handleShowCookieWebModal }) => (
        <FooterMenuNav locale={locale}
          handleClickTopic={handleClickTopic}
          handleChangeLanguage={handleChangeLanguage}
          handleShowCookieModal={handleShowCookieModal}
          handleShowPolicyMobileModal={handleShowPolicyMobileModal}
          handleShowPolicyModal={handleShowPolicyModal}
          handleShowCookieWebModal={handleShowCookieWebModal}
        />
      )}
    </PageSettings.Consumer>
  )
}