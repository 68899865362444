import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';

export default () => {


  return (
    <Container fluid='xl'>
      <Row>
        <Col lg={12}>
          <p className='footer-cp mrg-t-80 t-a-c'>
            <FormattedMessage id='footerCp' />
          </p>
        </Col>
      </Row>
    </Container>
  )
}