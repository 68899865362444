import React, { useState, useEffect, useRef, useContext } from 'react';
import { PageSettings } from '../config/page-settings';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';
import ResourceImg from '../assets/images/Resource.svg';
import CRMImg from '../assets/images/Features-CRM.svg';
import WorkforceImg from '../assets/images/Features-Workforce.svg';
import ProjectImg from '../assets/images/Project.svg';
import WorkdeskImg from '../assets/images/Workdesk.svg';
import TransportImg from '../assets/images/Transport.svg';
import _ from 'lodash';

export default () => {
  const app = useContext(PageSettings)
  const [visible, setVisible] = useState(true);
  const conRef = useRef();

  console.log('app', app)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
      if (_.get(entries, '[0].isIntersecting')) {
        app.setBurgerMark('fetures')
      }
    });
    observer.observe(conRef.current);
    return () => observer.unobserve(conRef.current);
  }, []);

  return (
    <PageSettings.Consumer>
      {({ locale }) => (
        <Container id='features-container' className='features-container' fluid='xl'>
          <div ref={conRef} className={`fade-in-section ${visible ? 'is-visible' : ''}`}>
            <Row>
              <Col className='t-a-c' lg={12}>
                <h2 className={`features-container-header-${locale}`}>
                  <FormattedMessage id='featuresHeader' />
                </h2>
                <hr className={`container-header-divider ${visible ? 'is-divider-visible' : ''}`} />
              </Col>
            </Row>

            <Row className='mrg-t-85'>
              <Col id='features-human-resources' xl={4} lg={6} sm={6} className='fetures-layout'>
                <div className='col-first'>
                  <img src={ResourceImg} />
                </div>
                <div className='col-second'>
                  <h3 className={`features-container-topic-${locale}`}>
                    <FormattedMessage id='featuresTopicHumanResources-1' />
                    <br />
                    <FormattedMessage id='featuresTopicHumanResources-2' />
                  </h3>
                  <p className={`features-container-description-${locale}`}>
                    <FormattedMessage id='featuresDescriptionHumanResources-1' />
                    <br />
                    <FormattedMessage id='featuresDescriptionHumanResources-2' />
                    <br />
                    <FormattedMessage id='featuresDescriptionHumanResources-3' />
                  </p>
                </div>
              </Col>
              <Col id='features-customer-relationship-management' xl={4} lg={6} sm={6} className='fetures-layout'>
                <div className='col-first'>
                  <img src={CRMImg} />
                </div>
                <div className='col-second'>
                  <h3 className={`features-container-topic-${locale}`}>
                    <FormattedMessage id='featuresTopicCustomerRelate-1' />
                    <br />
                    <FormattedMessage id='featuresTopicCustomerRelate-2' />
                  </h3>
                  <p className={`features-container-description-${locale}`}>
                    <FormattedMessage id='featuresDescriptionCustomerRelate-1' />
                    <br />
                    <FormattedMessage id='featuresDescriptionCustomerRelate-2' />
                    <br />
                    <FormattedMessage id='featuresDescriptionCustomerRelate-3' />
                  </p>
                </div>
              </Col>
              <Col id='features-workforce-management' xl={4} lg={6} sm={6} className='fetures-layout'>
                <div className='col-first'>
                  <img src={WorkforceImg} />
                </div>
                <div className='col-second'>
                  <h3 className={`features-container-topic-${locale}`}>
                    <FormattedMessage id='featuresTopicWorkforce-1' />
                    <br />
                    <FormattedMessage id='featuresTopicWorkforce-2' />
                  </h3>
                  <p className={`features-container-description-${locale}`}>
                    <FormattedMessage id='featuresDescriptionWorkforce-1' />
                    <br />
                    <FormattedMessage id='featuresDescriptionWorkforce-2' />
                    <br />
                    <FormattedMessage id='featuresDescriptionWorkforce-3' />
                  </p>
                </div>
              </Col>
              <Col id='features-project-management' xl={4} lg={6} sm={6} className='fetures-layout'>
                <div className='col-first'>
                  <img src={ProjectImg} />
                </div>
                <div className='col-second'>
                  <h3 className={`features-container-topic-${locale}`}>
                    <FormattedMessage id='featuresTopicProject-1' />
                    <br />
                    <FormattedMessage id='featuresTopicProject-2' />
                  </h3>
                  <p className={`features-container-description-${locale}`}>
                    <FormattedMessage id='featuresDescriptionProject-1' />
                    <br />
                    <FormattedMessage id='featuresDescriptionProject-2' />
                    <br />
                    <FormattedMessage id='featuresDescriptionProject-3' />
                  </p>
                </div>
              </Col>
              <Col id='features-work-desk' xl={4} lg={6} sm={6} className='fetures-layout'>
                <div className='col-first'>
                  <img src={WorkdeskImg} />
                </div>
                <div className='col-second'>
                  <h3 className={`features-container-topic-${locale}`}>
                    <FormattedMessage id='featuresTopicWorkdesk-1' />
                    <br />
                    <FormattedMessage id='featuresTopicWorkdesk-2' />
                  </h3>
                  <p className={`features-container-description-${locale}`}>
                    <FormattedMessage id='featuresDescriptionWorkdesk-1' />
                    <br />
                    <FormattedMessage id='featuresDescriptionWorkdesk-2' />
                    <br />
                    <FormattedMessage id='featuresDescriptionWorkdesk-3' />
                  </p>
                </div>
              </Col>
              <Col id='features-transport-management' xl={4} lg={6} sm={6} className='fetures-layout'>
                <div className='col-first'>
                  <img src={TransportImg} />
                </div>
                <div className='col-second'>
                  <h3 className={`features-container-topic-${locale}`}>
                    <FormattedMessage id='featuresTopicTransport-1' />
                    <br />
                    <FormattedMessage id='featuresTopicTransport-2' />
                  </h3>
                  <p className={`features-container-description-${locale}`}>
                    <FormattedMessage id='featuresDescriptionTransport-1' />
                    <br />
                    <FormattedMessage id='featuresDescriptionTransport-2' />
                    <br />
                    <FormattedMessage id='featuresDescriptionTransport-3' />
                  </p>
                </div>
              </Col>
            </Row>

            {/* <Row className='fetures-xl mrg-t-60'>

            </Row> */}
          </div>
        </Container>
      )}
    </PageSettings.Consumer>
  );

}