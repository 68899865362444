import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';
import FooterComponent from './footer';
import FacebookImg from '../../assets/images/facebook_grey.svg';
import LineImg from '../../assets/images/line_grey.svg';
import WhatsAppImg from '../../assets/images/whatsapp_grey.svg';
import LinkInImg from '../../assets/images/linkedIn_grey.svg';
import AppleStoreImg from '../../assets/images/appStore.svg';
import PlayStoreImg from '../../assets/images/playStore.svg';

export default (props) => {

  const { locale, handleClickTopic, handleChangeLanguage, handleShowCookieModal, handleShowPolicyMobileModal, handleShowPolicyModal, handleShowCookieWebModal } = props;

  // const [visible, setVisible] = useState(true);
  // const conRef = useRef();

  // useEffect(() => {
  //   const observer = new IntersectionObserver(entries => {
  //     entries.forEach(entry => setVisible(entry.isIntersecting));
  //   });
  //   observer.observe(conRef.current);
  //   return () => observer.unobserve(conRef.current);
  // }, []);

  return (
    // <div ref={conRef} className={`fade-in-section ${visible ? 'is-visible' : ''}`}>
    <div className='footer-padding'>
      <Container className='ft-resp' fluid='xl'>
        <Row className='footer-container-top'>
          <Col lg={2}>
            <p className={`footer-topic-en`}>
              <FormattedMessage id='footerNavTopicWorkD' />
            </p>
          </Col>
          <Col lg={4}>
            <p className={`footer-topic-${locale}`}>
              <FormattedMessage id='footerNavTopicFeatures' />
            </p>
          </Col>
          <Col lg={3}>
            <p className={`footer-topic-${locale}`}>
              <FormattedMessage id='footerNavTopicBusiness' />
            </p>
          </Col>
          <Col lg={3}>
            <p className={`footer-topic-${locale}`}>
              <FormattedMessage id='footerNavTopicPolicy' />
            </p>
          </Col>
        </Row>
        <Row className={`footer-container-description footer-container-description-${locale}`}>
          <Col lg={2}>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('home-container')}>
                <FormattedMessage id='footerNavHome' />
              </a>
            </Col>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-container')}>
                <FormattedMessage id='footerNavFeatures' />
              </a>
            </Col>
            {/* <Col className='mrg-b-4' lg={12}>
              <a>
                <FormattedMessage id='footerNavPricing' />
              </a>
            </Col> */}
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('contact-us-container')}>
                <FormattedMessage id='footerNavContactUS' />
              </a>
            </Col>
          </Col>
          <Col lg={4}>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-container')}>
                <FormattedMessage id='footerFeaturesHumanResources' />
              </a>
            </Col>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-container')}>
                <FormattedMessage id='footerFeaturesCustomerRelate' />
              </a>
            </Col>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-container')}>
                <FormattedMessage id='footerFeaturesWorkforce' />
              </a>
            </Col>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-container')}>
                <FormattedMessage id='footerFeaturesProject' />
              </a>
            </Col>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-container')}>
                <FormattedMessage id='footerFeaturesWorkdesk' />
              </a>
            </Col>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-container')}>
                <FormattedMessage id='footerFeaturesTransport' />
              </a>
            </Col>
          </Col>
          <Col lg={3}>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('business-human-resources')}>
                <FormattedMessage id='footerBusinessHr' />
              </a>
            </Col>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('business-engineer')}>
                <FormattedMessage id='footerBusinessEn' />
              </a>
            </Col>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('business-logistics')}>
                <FormattedMessage id='footerBusinessLs' />
              </a>
            </Col>
            {/* <Col className='mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('business-hospital')}>
                <FormattedMessage id='footerBusinessHp' />
              </a>
            </Col> */}
          </Col>
          <Col lg={3}>
            <Col className='mrg-b-4' lg={12}>
              <a onClick={handleShowPolicyModal}>
                <FormattedMessage id='footerPolicyPrivacy' />
              </a>
            </Col>
            <Col className='mrg-b-25' lg={12}>
              <a onClick={handleShowCookieWebModal}>
                <FormattedMessage id='footerPolicyCookies' />
              </a>
            </Col>
            {/* <Col className='mrg-t-10' lg={12}> */}
            <Col lg={12}>
              <a href='https://www.facebook.com/rapidtech.th' target='_blank'><img className='contact-icon' src={FacebookImg} /></a>
              <a href='https://www.linkedin.com/company/rapidtechth' target='_blank'><img className='contact-icon' src={LinkInImg} /></a>
            </Col>
            <Col className='mrg-t-45 mrg-b-4' lg={12}>
              <a href='https://apps.apple.com/us/app/rapidwork/id6444838997' target='_blank'><img className='app-store' src={AppleStoreImg} /></a>
              <a href='https://play.google.com/store/apps/details?id=com.rapidwork&pli=1' target='_blank'><img className='app-store' src={PlayStoreImg} /></a>
            </Col>
          </Col>
        </Row>
        <FooterComponent />
      </Container>
      <Container className='ft-resp-v' fluid='sm'>

        <Row className={`footer-container-description footer-container-description-${locale} t-a-c`}>
          <Col lg={12} sm={6}>
            <Col className='footer-container-top' lg={12}>
              <p className={`footer-topic-en`}>
                <FormattedMessage id='footerNavTopicWorkD' />
              </p>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('home-container')}>
                <FormattedMessage id='footerNavHome' />
              </a>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-container')}>
                <FormattedMessage id='footerNavFeatures' />
              </a>
            </Col>
            {/* <Col className='mrg-b-4' lg={12}>
              <a>
                <FormattedMessage id='footerNavPricing' />
              </a>
            </Col> */}
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('contact-us-container1')}>
                <FormattedMessage id='footerNavContactUS' />
              </a>
            </Col>
          </Col>
          <Col lg={12} sm={6}>
            <Col className='footer-container-top' lg={12}>
              <p className={`footer-topic-${locale}`}>
                <FormattedMessage id='footerNavTopicFeatures' />
              </p>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-human-resources')}>
                <FormattedMessage id='footerFeaturesHumanResources' />
              </a>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-customer-relationship-management')}>
                <FormattedMessage id='footerFeaturesCustomerRelate' />
              </a>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-workforce-management')}>
                <FormattedMessage id='footerFeaturesWorkforce' />
              </a>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-project-management')}>
                <FormattedMessage id='footerFeaturesProject' />
              </a>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-work-desk')}>
                <FormattedMessage id='footerFeaturesWorkdesk' />
              </a>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('features-transport-management')}>
                <FormattedMessage id='footerFeaturesTransport' />
              </a>
            </Col>
          </Col>
          <Col lg={12} sm={6}>
            <Col className='footer-container-top' lg={12}>
              <p className={`footer-topic-${locale}`}>
                <FormattedMessage id='footerNavTopicBusiness' />
              </p>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('business-human-resources')}>
                <FormattedMessage id='footerBusinessHr' />
              </a>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('business-engineer1')}>
                <FormattedMessage id='footerBusinessEn' />
              </a>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('business-logistics1')}>
                <FormattedMessage id='footerBusinessLs' />
              </a>
            </Col>
            {/* <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={() => handleClickTopic('business-hospital1')}>
                <FormattedMessage id='footerBusinessHp' />
              </a>
            </Col> */}
          </Col>
          <Col lg={12} sm={6}>
            <Col className='footer-container-top' lg={12}>
              <p className={`footer-topic-${locale}`}>
                <FormattedMessage id='cookie-ft-1' />
              </p>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={handleShowPolicyMobileModal}>
                <FormattedMessage id='cookie-ft-2' />
              </a>
            </Col>
            <Col className='l-a-f mrg-b-4' lg={12}>
              <a onClick={handleShowCookieModal}>
                <FormattedMessage id='cookie-ft-3' />
              </a>
            </Col>
          </Col>
          {/* <Col sm={12}>
            <Col className='l-a-f mrg-b-4 mrg-t-40' lg={12}>
              <a onClick={() => handleChangeLanguage('en')}>EN</a> | <a onClick={() => handleChangeLanguage('th')}>TH</a>
            </Col>
          </Col> */}
          <Col className='t-a-c mrg-t-40' sm={12}>
            <Col lg={12}>
              <a href='https://www.facebook.com/rapidtech.th' target='_blank'><img className='contact-icon' src={FacebookImg} /></a>
           
              <a href='https://www.linkedin.com/company/rapidtechth' target='_blank'><img className='contact-icon' src={LinkInImg} /></a>
            </Col>
            <Col className='mrg-t-45 mrg-b-4' lg={12}>
              <a href='https://apps.apple.com/us/app/rapidwork/id6444838997' target='_blank'><img className='app-store' src={AppleStoreImg} /></a>
              <a href='https://play.google.com/store/apps/details?id=com.rapidwork&pli=1' target='_blank'><img className='app-store' src={PlayStoreImg} /></a>
            </Col>
          </Col>
        </Row>

        <FooterComponent />
      </Container>
    </div>
  )
}