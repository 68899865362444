import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";

// css
import 'antd/dist/antd.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './re-design/assets/css/main.css';
import './re-design/assets/css/navbar.css';
import './re-design/assets/css/home.css';
import './re-design/assets/css/features.css';
import './re-design/assets/css/business.css';
import './re-design/assets/css/contact-us.css';
import './re-design/assets/css/footer.css';
import './re-design/assets/css/modal.css';
import './re-design/assets/css/send-email.css';
import './re-design/assets/css/contact-policy.css';


ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
