import React, { useState, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Container, Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { notification } from 'antd';
import { sendContact } from '../../contollers/contact-controller';

export default (props) => {
  const intl = useIntl();
  const modalProps = { ...props };
  _.unset(modalProps, ['locale']);
  _.unset(modalProps, ['contactTopicEn']);
  _.unset(modalProps, ['contactTopicTh']);
  _.unset(modalProps, ['handleShowPolicyModal']);
  _.unset(modalProps, ['policyStatus']);
  const { locale, contactTopicEn, contactTopicTh, handleShowPolicyModal, policyStatus, show, onHide } = props;
  const [validated, setValidated] = useState(false);
  const [topicClass, setTopicClass] = useState();
  const checkboxRef = useRef();

  const dataTopic = [
    {
      id: 1,
      name: intl.formatMessage({ id: 'contact-tp-1' }),
    },
    {
      id: 2,
      name: intl.formatMessage({ id: 'contact-tp-2' }),
    },
    {
      id: 3,
      name: intl.formatMessage({ id: 'contact-tp-3' }),
    },
    {
      id: 4,
      name: intl.formatMessage({ id: 'contact-tp-4' }),
    },
    {
      id: 5,
      name: intl.formatMessage({ id: 'contact-tp-5' }),
    },
  ];

  useEffect(() => {
    setValidated(false);
  }, [show]);

  useEffect(() => {
    if (checkboxRef.current && policyStatus) {
      checkboxRef.current.checked = policyStatus;
      console.log(checkboxRef.current.checked);
    }
  }, [policyStatus]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      // event.preventDefault();
      // event.stopPropagation();
    } else {
      const data = {
        fullname: form['fullname'].value,
        email: form['email'].value,
        phone: form['phone'].value,
        companyName: form['companyName'].value,
        companyWebsite: form['companyWebsite'].value,
        status: form['topic'].value,
        description: form['description'].value,
        lang: _.toUpper(locale),
      };
      const result = await sendContact(data);
      notification[result.success ? 'success' : 'error']({
        message: result.message,
        style: {
          fontFamily: locale === 'en' ? 'SFThonburi-Regular' : 'IBMPlexSansThai-Regular',
          color: '#65676b',
        },
      });
      onHide();
      return;
    }

    setValidated(true);
  };

  const handleChangTopic = (e) => {
    setTopicClass(e.target.value);
  };

  const handleKeyDownNumber = (e) => {
    let keyCode = e.key.charCodeAt(0);
    console.log('keyCode',keyCode);
    if ((keyCode >= 48 && keyCode <= 57) || keyCode === 65 || keyCode === 66 || keyCode === 43 ) {
      return true;
    }
    else {
      e.preventDefault();
    }
  }

  return (
    <Modal {...modalProps} className='send-email-modal' size='xl' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h1 className={`contact-form-header-${locale}`}>
          <FormattedMessage id='contactFormHeader' />
        </h1>
        <Container className='send-email-container' fluid='xl'>
          {show ? (
            <Form id='form-contact-us' noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col lg={6}>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='fullname'>
                      <Form.Control
                        className={`contact-form-input-${locale}`}
                        required
                        type='text'
                        placeholder={intl.formatMessage({ id: 'contactInputFullName' })}
                      />
                      <Form.Control.Feedback className={`contact-invalid-${locale}`} type='invalid'>
                        <FormattedMessage id='contactInputValidFullName' />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='7' controlId='email'>
                      <Form.Control
                        className={`contact-form-input-${locale}`}
                        required
                        type='email'
                        placeholder={intl.formatMessage({ id: 'contactInputEmail' })}
                      />
                      <Form.Control.Feedback className={`contact-invalid-${locale}`} type='invalid'>
                        <FormattedMessage id='contactInputValidEmail' />
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md='5' controlId='phone'>
                      <Form.Control
                        className={`contact-form-input-${locale}`}
                        required
                        onKeyDown={handleKeyDownNumber}
                        placeholder={intl.formatMessage({ id: 'contactInputPhone' })}
                      />
                      <Form.Control.Feedback className={`contact-invalid-${locale}`} type='invalid'>
                        <FormattedMessage id='contactInputValidPhone' />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='companyName'>
                      <Form.Control
                        className={`contact-form-input-${locale}`}
                        type='text'
                        placeholder={intl.formatMessage({ id: 'contactInputCompanyName' })}
                      />
                    </Form.Group>
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='companyWebsite'>
                      <Form.Control
                        className={`contact-form-input-${locale}`}
                        type='text'
                        placeholder={intl.formatMessage({ id: 'contactInputCompanyWebSite' })}
                      />
                    </Form.Group>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='topic'>
                      <Form.Select
                        required
                        className={`contact-form-input-${locale} ${topicClass ? '' : 'first-value-select'}`}
                        aria-label='Default select example'
                        defaultValue=''
                        onChange={handleChangTopic}
                      >
                        <option value='' disabled hidden>
                          {intl.formatMessage({ id: 'contactInputTopic' })}
                        </option>
                        {/* {locale === 'en' ?
                          contactTopicEn.map((item) => <option key={item.text} value={item.text}>{item.text}</option>)
                          : 
                          contactTopicTh.map((item) => <option key={item.text} value={item.text}>{item.text}</option>)
                        } */}
                        {dataTopic.map((item) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback className={`contact-invalid-${locale}`} type='invalid'>
                        <FormattedMessage id='contactInputValidTopic' />
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId='description'>
                      <Form.Control
                        as='textarea'
                        className={`contact-form-input-${locale}`}
                        placeholder={intl.formatMessage({ id: 'contactInputDescription' })}
                        rows={3}
                      />
                    </Form.Group>
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group md='12'>
                      <Form.Check
                        ref={checkboxRef}
                        required
                        label={
                          <span className={`contact-policy-${locale}`}>
                            &nbsp;
                            <FormattedMessage id='contactCheckedPolicy-1' />
                            <a className='contact-policy' onClick={handleShowPolicyModal}>
                              <FormattedMessage id='contactCheckedPolicy-2' />
                            </a>
                            <FormattedMessage id='contactCheckedPolicy-3' />
                          </span>
                        }
                        feedback={<span className={`contact-invalid-${locale}`}>{intl.formatMessage({ id: 'contactInputValidPolicy' })}</span>}
                        feedbackType='invalid'
                      />
                    </Form.Group>
                  </Row>
                </Col>
              </Row>
              <Row className='mrg-t-45'>
                <Col className='t-a-c' lg={12}>
                  <Button className={`contact-submit contact-submit-${locale}`} type='submit'>
                    <FontAwesomeIcon icon={faPaperPlane} />
                    &nbsp;&nbsp; <FormattedMessage id='contactBtnSendContact' />
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : null}
        </Container>
      </Modal.Body>
    </Modal>
  );
};
