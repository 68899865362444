import React, { useState, useEffect } from "react";
import { PageSettings } from "./re-design/config/page-settings";
import { IntlProvider } from "react-intl";
import TopMenu from "./re-design/components/top-menu/top-menu";
import FooterMenu from "./re-design/components/footer/footer-menu";
import { Container } from "react-bootstrap";
import langEN from "./re-design/languages/en.json";
import langTH from "./re-design/languages/th.json";
import HomeImg from "./re-design/assets/images/Home.png";
import EllipseImg from "./re-design/assets/images/Ellipse.png";
import HomeComponent from "./re-design/pages/home";
import FeaturesComponent from "./re-design/pages/features";
import BusinessComponent from "./re-design/pages/business";
import ContactUsComponent from "./re-design/pages/contact-us";
import SendEmailModal from "./re-design/components/modal/send-email";
import SendEmailMobile from "./re-design/components/modal/send-email-mobile";
import CookieMobile from "./re-design/components/modal/cookie-mobile";
import CookieSettingMobile from "./re-design/components/modal/cookie-setting-mobile";
import CookieSettingWeb from "./re-design/components/modal/cookie-setting-web";
import ContactPolicyModal from "./re-design/components/modal/contact-policy";
import CookieWeb from "./re-design/components/modal/cookie-web";
import ContactPolicyMobileModal from "./re-design/components/modal/contact-policy-mobile";
import { getContactTopic } from "./re-design/contollers/contact-controller";
import CookieConsent, { Cookies } from "react-cookie-consent";
import "./App.css";
import { FormattedMessage } from "react-intl";
import TagManager from "react-gtm-module";

export default () => {
  const [initail, setInitail] = useState(false);
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState(langEN);
  const [contactTopicEn, setContactTopicEn] = useState([]);
  const [contactTopicTh, setContactTopicTh] = useState([]);
  const [sendEmailVisible, setSendEmailVisible] = useState(false);
  const [sendEmailMobileVisible, setSendEmailMobileVisible] = useState(false);
  const [countClickContact, setCountClickContact] = useState(0);
  const [policyVisible, setPolicyVisible] = useState(false);
  const [policyMobileVisible, setPolicyMobileVisible] = useState(false);
  const [visibleCookieMobile, setVisibleCookieMobile] = useState(false);
  const [visibleCookieWeb, setVisibleCookieWeb] = useState(false);
  const [visibleCookieSettingMobile, setVisibleCookieSettingMobile] =
    useState(false);
  const [visibleCookieSettingWeb, setVisibleCookieSettingWeb] = useState(false);
  const [policyStatus, setPolicyStatus] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [burgerMark, setBurgerMark] = useState("home");
  const [checkedSwitchCookie, setCheckedSwitchCookie] = useState({
    strictly: true,
    analytical: false,
    functionality: false,
    target: false,
    advertising: false,
  });

  const [visibleCookieTab, setVisibleCookieTab] = useState("show");

  // const tagManagerArgs = {
  //   gtmId: "GTM-K92XSKS",
  // };

  // useEffect(() => {

  // }, []);

  let cookieAyt = Cookies.get("rapidWorkcookie2");
  let cookieStl = Cookies.get("rapidWorkcookie1");

  useEffect(() => {
    if (cookieAyt !== undefined) {
     //TagManager.initialize(tagManagerArgs);
    }
  }, [cookieAyt]);

  useEffect(() => {
    if (cookieStl !== undefined) {
      setVisibleCookieTab("hidden");
    }
  }, [cookieStl]);

  console.log("cookieAyt ::", cookieAyt);

  // useEffect(() => {
  //   const getData = async () => {
  //     const langEN = await getContactTopic("EN");
  //     const langTH = await getContactTopic("TH");
  //     console.log('status',langEN);
  //     setInitail(true);
  //     setContactTopicEn(langEN);
  //     setContactTopicTh(langTH);
  //   };

  //   if (!initail) getData();
  // });

  const handleChangeLanguage = (val) => {
    let ms = {};

    switch (val) {
      case "en":
        ms = langEN;
        break;
      case "th":
        ms = langTH;
        break;
      default:
        ms = langEN;
    }

    setLocale(val);
    setMessages(ms);
  };

  const handleShowSendEmailModal = () => {
    setPolicyStatus(false);
    setCountClickContact(countClickContact + 1);
    setSendEmailVisible(true);
  };

  const handleShowSendEmailMobileModal = () => {
    setPolicyStatus(false);
    setCountClickContact(countClickContact + 1);
    setSendEmailMobileVisible(true);
  };

  const handleHideSendEmailModal = () => {
    setSendEmailVisible(false);
    setSendEmailMobileVisible(false);
  };

  const handleClickTopic = (id) => {
    let block = "center";
    if (id === "home-container") block = "end";
    const titleElement = document.getElementById(id);
    console.log("titleElement ::", titleElement);
    titleElement.scrollIntoView({
      behavior: "smooth",
      block: block,
      inline: "nearest",
    });
    setToggleMenu(false);
  };

  const handleShowPolicyModal = () => {
    setPolicyStatus(false);
    setPolicyVisible(true);
  };

  const handleShowPolicyMobileModal = () => {
    setPolicyStatus(false);
    setPolicyMobileVisible(true);
  };

  const handleHidePolicyModal = (status) => {
    if (status) setPolicyStatus(status);
    setPolicyVisible(false);
    setPolicyMobileVisible(false);
  };

  const handleShowCookieModal = () => {
    setVisibleCookieMobile(true);
  };

  const handleShowCookieWebModal = () => {
    setVisibleCookieWeb(true);
  };

  const handleHideCookieModal = () => {
    setVisibleCookieMobile(false);
    setVisibleCookieSettingMobile(false);
  };

  const handleHideCookieMobileModal = () => {
    setVisibleCookieWeb(false);
    setVisibleCookieSettingWeb(false);
  };

  const handleToggleOpenMenu = (e) => {
    console.log("-----------------------------------------------");
    setToggleMenu(!toggleMenu);
  };

  const handleToggleCloseMenu = (e) => {
    setToggleMenu(false);
  };

  const SetAllCookies = (type) => {
    if (type === "ALL") {
      var today = new Date();
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      Cookies.set("rapidWorkcookie2", "Analytical/Performance Cookies", {
        path: "/",
        expires: tomorrow,
      });
      Cookies.set("rapidWorkcookie3", "Functionality Cookies", {
        path: "/",
        expires: tomorrow,
      });
      Cookies.set("rapidWorkcookie4", "Targeting Cookies", {
        path: "/",
        expires: tomorrow,
      });
      Cookies.set("rapidWorkcookie5", "Advertising Cookies", {
        path: "/",
        expires: tomorrow,
      });
      // TagManager.initialize(tagManagerArgs);
    } else {
      var today = new Date();
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      if (checkedSwitchCookie.analytical === true) {
        Cookies.set("rapidWorkcookie2", "Analytical/Performance Cookies", {
          path: "/",
          expires: tomorrow,
        });
        //TagManager.initialize(tagManagerArgs);
      }
      if (checkedSwitchCookie.functionality === true) {
        Cookies.set("rapidWorkcookie3", "Functionality Cookies", {
          path: "/",
          expires: tomorrow,
        });
      }
      if (checkedSwitchCookie.target === true) {
        Cookies.set("rapidWorkcookie4", "Targeting Cookies", {
          path: "/",
          expires: tomorrow,
        });
      }
      if (checkedSwitchCookie.advertising === true) {
        Cookies.set("rapidWorkcookie5", "Advertising Cookies", {
          path: "/",
          expires: tomorrow,
        });
      }
    }

    setVisibleCookieSettingMobile(false);
    setVisibleCookieSettingWeb(false);
    setVisibleCookieTab("hidden");
    // console.log('Cookies',)
  };

  const clearCookie = () => {
    Cookies.remove("rapidWorkcookie1");
    setVisibleCookieTab("show");
  };

  const SettingCookiesMobile = () => {
    setVisibleCookieSettingMobile(true);
  };

  const SettingCookies = () => {
    setVisibleCookieSettingWeb(true);
  };

  const onSwitchCookie = (type) => {
    setCheckedSwitchCookie((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  return (
    <IntlProvider messages={messages} locale={locale}>
      <PageSettings.Provider
        value={{
          locale,
          contactTopicEn,
          contactTopicTh,
          toggleMenu,
          handleChangeLanguage,
          handleShowSendEmailModal,
          handleClickTopic,
          handleToggleOpenMenu,
          handleToggleCloseMenu,
          setBurgerMark,
          burgerMark,
          handleShowSendEmailMobileModal,
          handleShowCookieModal,
          handleShowPolicyMobileModal,
          handleShowPolicyModal,
          handleShowCookieWebModal,
        }}
      >
        <div
          className="bg-image"
          style={{
            position: "relative",
            // backgroundImage: `url(${HomeImg})`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <div>
            <img src={HomeImg} className="background-image" />
          </div>
          <TopMenu />
          <Container className="main-container" fluid="xl">
            {/* <div className='bg-ellipse-home'><img src={EllipseImg} /></div> */}
            <div className="bg-img-home">
              <img src={HomeImg} />
            </div>
            <HomeComponent />
            <FeaturesComponent />
            <BusinessComponent />
            <ContactUsComponent />
          </Container>
          <div className="footer-container">
            <FooterMenu />
          </div>

          <SendEmailModal
            locale={locale}
            contactTopicEn={contactTopicEn}
            contactTopicTh={contactTopicTh}
            handleShowPolicyModal={handleShowPolicyModal}
            policyStatus={policyStatus}
            show={sendEmailVisible}
            onHide={handleHideSendEmailModal}
          />

          <SendEmailMobile
            locale={locale}
            contactTopicEn={contactTopicEn}
            contactTopicTh={contactTopicTh}
            handleShowPolicyModal={handleShowPolicyMobileModal}
            policyStatus={policyStatus}
            show={sendEmailMobileVisible}
            onHide={handleHideSendEmailModal}
          />

          <ContactPolicyModal
            locale={locale}
            show={policyVisible}
            onHide={handleHidePolicyModal}
            backdrop="static"
            keyboard={false}
          />

          <ContactPolicyMobileModal
            locale={locale}
            show={policyMobileVisible}
            onHide={handleHidePolicyModal}
            backdrop="static"
            keyboard={false}
          />

          <div className="hide-cookie-mobile">
            <CookieConsent
              enableDeclineButton
              flipButtons={true}
              location="bottom"
              onAccept={() => SetAllCookies("ALL")}
              onDecline={SettingCookiesMobile}
              containerClasses="cookie-content-background-mobile"
              buttonClasses={`cookie-content-button-mobile-${locale}`}
              declineButtonClasses={`cookie-content-setting-mobile-${locale}`}
              buttonText={<FormattedMessage id="cookie-button-acept" />}
              declineButtonText={
                <FormattedMessage id="cookie-button-decline" />
              }
              hideOnDecline={false}
              setDeclineCookie={true}
              cookieName="rapidWorkcookie1"
              cookieValue="Strictly Necessary Cookies"
              expires={1}
              visible={visibleCookieTab}
            >
              <p className={`cookie-content-text-mobile-1-${locale}`}>
                <FormattedMessage id="cookie-text-1" />
              </p>
              <div style={{ textAlign: "center" }}>
                <span className={`cookie-content-text-2-${locale}`}>
                  <FormattedMessage id="cookie-text-2" /> <br />
                  <FormattedMessage id="cookie-text-3" /> <br />
                  <FormattedMessage id="cookie-text-4" /> <br />
                  <FormattedMessage id="cookie-text-5" />
                  &nbsp;
                  <span
                    style={{ color: "#000048" }}
                    className={`cookie-content-text-2-${locale}`}
                    onClick={handleShowCookieModal}
                  >
                    <FormattedMessage id="cookie-text-6" />
                  </span>
                </span>
              </div>
            </CookieConsent>
          </div>

          <CookieMobile
            locale={locale}
            show={visibleCookieMobile}
            onHide={handleHideCookieModal}
            backdrop="static"
            keyboard={false}
            handleShowPolicyMobileModal={handleShowPolicyMobileModal}
          />

          <CookieSettingMobile
            locale={locale}
            show={visibleCookieSettingMobile}
            onHide={handleHideCookieModal}
            backdrop="static"
            keyboard={false}
            checkedSwitchCookie={checkedSwitchCookie}
            onSwitchCookie={onSwitchCookie}
            SetAllCookies={SetAllCookies}
            clearCookie={clearCookie}
          />

          <div className="hide-cookie-web">
            <CookieConsent
              style={{
                transform: "translate(-25%, 10%)",
              }}
              enableDeclineButton
              flipButtons={true}
              location="bottom"
              onAccept={() => SetAllCookies("ALL")}
              onDecline={SettingCookies}
              containerClasses="cookie-content-background"
              buttonClasses={`cookie-content-button-${locale}`}
              declineButtonClasses={`cookie-content-setting-${locale}`}
              buttonText={<FormattedMessage id="cookie-button-acept" />}
              declineButtonText={
                <FormattedMessage id="cookie-button-decline" />
              }
              hideOnDecline={false}
              setDeclineCookie={true}
              cookieName="rapidWorkcookie1"
              cookieValue="Strictly Necessary Cookies"
              expires={1}
              visible={visibleCookieTab}
            >
              <p className={`cookie-content-text-1-${locale}`}>
                <FormattedMessage id="cookie-text-1" />
              </p>
              <span className={`cookie-content-text-2-${locale}`}>
                <FormattedMessage id="cookie-text-7" /> <br />
                <FormattedMessage id="cookie-text-8" />
                &nbsp;
                <span
                  style={{ color: "#000048", cursor: "pointer" }}
                  className={`cookie-content-text-2-${locale}`}
                  onClick={handleShowCookieWebModal}
                >
                  <FormattedMessage id="cookie-text-6" />
                </span>
              </span>
            </CookieConsent>
          </div>

          <CookieWeb
            locale={locale}
            show={visibleCookieWeb}
            onHide={handleHideCookieMobileModal}
            backdrop="static"
            keyboard={false}
            handleShowPolicyModal={handleShowPolicyModal}
          />

          <CookieSettingWeb
            locale={locale}
            show={visibleCookieSettingWeb}
            onHide={handleHideCookieMobileModal}
            backdrop="static"
            keyboard={false}
            checkedSwitchCookie={checkedSwitchCookie}
            onSwitchCookie={onSwitchCookie}
            SetAllCookies={SetAllCookies}
            clearCookie={clearCookie}
          />
        </div>
      </PageSettings.Provider>
    </IntlProvider>
  );
};
