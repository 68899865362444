import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Container, Modal, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import '../../../re-design/assets/css/cookie-policy-mobile.css'

export default (props) => {
  const modalProps = { ...props };
  _.unset(modalProps, ['locale']);
  const { locale, show, onHide, handleShowPolicyMobileModal } = props;
  const [statusRead, setStatusRead] = useState(true);
  const listInnerRef = useRef();

  useEffect(() => {
    setStatusRead(true);
  }, [show]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      console.log('sc1', scrollTop, clientHeight, scrollHeight)
      if (scrollTop + clientHeight >= (scrollHeight - 50)) {
        setStatusRead(false);
      }
    }
  };

  return (
    <Modal
      {...modalProps}
      className='cookie-modal-mobile'
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <h1 className={`contact-policy-header-${locale}`}><FormattedMessage id='cookie-text-6' /></h1>
      <div
        className={`contact-policy-content`}
        onScroll={onScroll}
        ref={listInnerRef}
      >
        <Modal.Body>
          <Container fluid='xl'>
            <Row>
              <Col lg={12}>
                <p className={`contact-policy-content-1 contact-policy-content-1-${locale} t-a-c`}>
                  <FormattedMessage id='cookie-md-1' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-50`}>
                  <FormattedMessage id='cookie-md-2' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-2-${locale} mrg-t-15`} style={{ color: '#00004e' }}>
                  <FormattedMessage id='cookie-md-3' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale} mrg-t-25`} style={{ marginBottom: 'unset' }}>
                  <FormattedMessage id='cookie-md-4' /><span className={`contact-policy-content-2 contact-policy-content-3-${locale}`} onClick={() => { window.open("https://allaboutcookies.org/") }}>
                    <FormattedMessage id='cookie-md-5' />
                  </span>
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-2-${locale} mrg-t-60`} style={{ color: '#00004e' }}>
                  <FormattedMessage id='cookie-md-6' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale}`}>
                  <FormattedMessage id='cookie-md-7' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-2-${locale} mrg-t-60`} style={{ color: '#00004e' }}>
                  <FormattedMessage id='cookie-md-8' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale}`}>
                  <FormattedMessage id='cookie-md-9' /> &nbsp;
                  <span className={`contact-policy-content-2 contact-policy-content-3-${locale}`} onClick={handleShowPolicyMobileModal}>
                    <FormattedMessage id='cookie-ft-2' />
                  </span>
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-2-${locale} mrg-t-60`} style={{ color: '#00004e' }}>
                  <FormattedMessage id='cookie-md-10' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale}`}>
                  <FormattedMessage id='cookie-md-11' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-2-${locale}`}>
                  <FormattedMessage id='cookie-md-12' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale}`}>
                  <FormattedMessage id='cookie-md-13' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-30`}>
                  <FormattedMessage id='cookie-md-14' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale}`}>
                  <FormattedMessage id='cookie-md-15' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-30`}>
                  <FormattedMessage id='cookie-md-16' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale}`}>
                  <FormattedMessage id='cookie-md-17' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-2-${locale} mrg-t-30`}>
                  <FormattedMessage id='cookie-md-18' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale}`}>
                  <FormattedMessage id='cookie-md-19' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-2-${locale} mrg-t-60`} style={{ color: '#00004e' }}>
                  <FormattedMessage id='cookie-md-20' />
                </p>

                <p className={`contact-policy-content-1 contact-policy-content-1-${locale}`}>
                  <FormattedMessage id='cookie-md-21' />
                </p>

              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </div>
    </Modal >
  );
}