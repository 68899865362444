import HTTPClient from 'axios';

const getContactTopic = async (lang = 'EN') => {
  let data = [];
  try {
    const response = await HTTPClient.get(`${process.env.REACT_APP_URL_MANAGER}/v3/websiteworkd/manager/workd/${lang}`);
    if (response.status === 200) {
      response.data.status.map((item, index) => 
        data.push({
          key: index,
          text: item,
          isChecked: false,
        })
      );
    }
  }
  catch (e) {
    data = [];
  }

  return data;
}

const sendContact = async (data) => {
  let status = false;
  let message = '';

  try {
    const body = {
      fullname : data.fullname,
      email : data.email,
      phone : data.phone,
      companyName: data.companyName,
      companyWebsite: data.companyWebsite,
      status : data.status,
      description : data.description,
      lang : data.lang
    }

    const response = await HTTPClient.post(`${process.env.REACT_APP_URL_MANAGER}/v3/websiteworkd/manager/workd/create/data`, body);
    if (response.status === 200) {
      status = true;
      message = response.data.message;
    }
  }
  catch (e) {
    message = e.response.data.messsage;
  }

  return {success: status, message: message};
}

export {
  getContactTopic,
  sendContact
}