import React, { useState, useEffect, useRef } from 'react';
import { PageSettings } from '../config/page-settings';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';
import HrImg from '../assets/images/HR.png';
import EngineerImg from '../assets/images/Engineer.png';
import LogisticsImg from '../assets/images/Logistics.png';
import HospitalImg from '../assets/images/Hospital.png';

export default () => {

  const [visible, setVisible] = useState(true);
  const [hrVisible, setHrVisible] = useState(true);
  const [enVisible, setEnVisible] = useState(true);
  const [lsVisible, setLsVisible] = useState(true);
  const [hpVisible, setHpVisible] = useState(true);
  const conRef = useRef();
  const hrRef = useRef();
  const enRef = useRef();
  const lsRef = useRef();
  const hpRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(conRef.current);
    return () => observer.unobserve(conRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setHrVisible(entry.isIntersecting));
    });
    observer.observe(hrRef.current);
    return () => observer.unobserve(hrRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setEnVisible(entry.isIntersecting));
    });
    observer.observe(enRef.current);
    return () => observer.unobserve(enRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setLsVisible(entry.isIntersecting));
    });
    observer.observe(lsRef.current);
    return () => observer.unobserve(lsRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setHpVisible(entry.isIntersecting));
    });
    observer.observe(hpRef.current);
    return () => observer.unobserve(hpRef.current);
  }, []);

  return (
    <PageSettings.Consumer>
      {({ locale }) => (
        <Container className='business-container' fluid='xl'>
          <div ref={conRef} className={`fade-in-section ${visible ? 'is-visible' : ''}`}>
            <Row>
              <Col className='t-a-c' lg={12}>
                <h2 className={`business-container-header-${locale}`}>
                  <FormattedMessage id='businessHeader-1' />
                  <br />
                  <FormattedMessage id='businessHeader-2' />
                </h2>
                <hr className={`container-header-divider ${visible ? 'is-divider-visible' : ''}`} />
              </Col>
            </Row>
          </div>
          <div ref={hrRef} className={`fade-in-section ${hrVisible ? 'is-visible' : ''}`}>
            <Row id='business-human-resources' className='mrg-t-100'>
              <Col className='t-a-c' lg={6}>
                <img src={HrImg} className='business-container-left' />
              </Col>
              <Col className='business-container-content-end t-a-c' lg={6}>
                <h1 className={`business-container-topic-${locale}`}>
                  <FormattedMessage id='businessTopicHr' />
                </h1>
                <p className={`business-container-description-start-${locale}`} style={{ maxWidth: '460px' }}>
                  <FormattedMessage id='businessDescriptionHr-1' />
                </p>
              </Col>
            </Row>
          </div>
          <div ref={enRef} className={`fade-in-section ${enVisible ? 'is-visible' : ''}`}>
            <Row id='business-engineer' className='ft-resp mrg-t-30'>
              <Col className='business-container-content-end t-a-c' lg={6}>
                <h1 className={`business-container-topic-${locale}`}>
                  <FormattedMessage id='businessTopicEn' />
                </h1>
                <p className={`business-container-description-start-${locale}`} style={{ maxWidth: '460px' }}>
                  <FormattedMessage id='businessDescriptionEn-1' />
                </p>
              </Col>
              <Col className='t-a-c' lg={6}>
                <img src={EngineerImg} className='business-container-right' />
              </Col>
            </Row>
            <Row id='business-engineer1' className='ft-resp-v mrg-t-100'>
              <Col className='t-a-c' lg={6}>
                <img src={EngineerImg} className='business-container-right' />
              </Col>
              <Col className='business-container-content-start t-a-c' lg={6}>
                <h1 className={`business-container-topic-${locale}`}>
                  <FormattedMessage id='businessTopicEn' />
                </h1>
                <p className={`business-container-description-end-${locale}`}>
                  <FormattedMessage id='businessDescriptionEn-1' />
                </p>
              </Col>
            </Row>
          </div>
          <div ref={lsRef} className={`fade-in-section ${lsVisible ? 'is-visible' : ''}`}>
            <Row id='business-logistics' className='ft-resp mrg-t-30'>
              <Col className='t-a-c' lg={6}>
                <img src={LogisticsImg} className='business-container-left' />
              </Col>
              <Col className='business-container-content-end t-a-c' lg={6}>
                <h1 className={`business-container-topic-${locale}`}>
                  <FormattedMessage id='businessTopicLs' />
                </h1>
                <p className={`business-container-description-start-${locale}`} style={{ maxWidth: '460px' }}>
                  <FormattedMessage id='businessDescriptionLs-1' />
                </p>
              </Col>
            </Row>
            <Row id='business-logistics1' className='ft-resp-v mrg-t-100'>
              <Col className='t-a-c' lg={6}>
                <img src={LogisticsImg} className='business-container-left' />
              </Col>
              <Col className='business-container-content-end t-a-c' lg={6}>
                <h1 className={`business-container-topic-${locale}`}>
                  <FormattedMessage id='businessTopicLs' />
                </h1>
                <p className={`business-container-description-start-${locale}`}>
                  <FormattedMessage id='businessDescriptionLs-1' />
                </p>
              </Col>
            </Row>
          </div>
          <div ref={hpRef} className={`fade-in-section ${hpVisible ? 'is-visible' : ''}`}>
            <Row id='business-hospital1' className='ft-resp-v mrg-t-100'>
            </Row>
          </div>
        </Container>
      )}
    </PageSettings.Consumer>
  );
}