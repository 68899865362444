import React, { useRef, useContext, useEffect } from 'react';
import { PageSettings } from '../config/page-settings';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import BrandColor from '../assets/images/RapidWorkLogo.png';
import ResourceImg from '../assets/images/Resources.svg';
import CrmImg from '../assets/images/CRM.svg';
import WorkforceImg from '../assets/images/Workforce.svg';
import _ from 'lodash';

export default () => {
  const refHome = useRef()
  const app = useContext(PageSettings)

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (_.get(entries, '[0].isIntersecting')) {
        app.setBurgerMark('home')
      }
    });
    observer.observe(refHome.current);
    return () => observer.unobserve(refHome.current);
  }, []);

  return (
    <PageSettings.Consumer>
      {({ locale, handleShowSendEmailModal, handleShowSendEmailMobileModal }) => (
        <Container id='home-container' className='home-container' fluid='xl' ref={refHome}>
          <Row className='mrg-b-40'>
            <Col className='pad-l-0' lg={6}>
              <img className='home-logo' src={BrandColor} />
            </Col>
          </Row>
          <Row className='mrg-b-10'>
            <Col lg={6}>
              <h1 className={`home-header-topic-${locale}`}>
                <FormattedMessage id='homeHeaderTopic-1' />
                <br />
                <FormattedMessage id='homeHeaderTopic-2' />
              </h1>
            </Col>
          </Row>
          <Row className='mrg-b-15'>
            <Col lg={6}>
              <p className={`home-header-description-${locale}`}>
                <FormattedMessage id='homeHeaderDescription-1' />
                <br />
                <FormattedMessage id='homeHeaderDescription-2' />
                <br />
                <FormattedMessage id='homeHeaderDescription-3' />
              </p>
            </Col>
          </Row>
          <Row className='mrg-b-30'>
            <Col className='ft-resp-v-Rectangle-17213' lg={6}>
              <div className='Rectangle-17213'>
                <div className='Rectangle-icon'>
                  <img src={ResourceImg} />
                </div>
                <div className='Rectangle-topic'>
                  <span className={`Rectangle-topic-${locale}`}>
                    <FormattedMessage id='homeCardResourceTopic' />
                  </span>
                </div>
                <div className='Rectangle-description'>
                  <span className={`Rectangle-description-${locale}`}>
                    <FormattedMessage id='homeCardResourceDescription-1' />
                    <br />
                    <FormattedMessage id='homeCardResourceDescription-2' />
                    <br />
                    <FormattedMessage id='homeCardResourceDescription-3' />
                  </span>
                </div>
              </div>
              <div className='Rectangle-17213'>
                <div className='Rectangle-icon'>
                  <img src={CrmImg} />
                </div>
                <div className='Rectangle-topic'>
                  <span className={`Rectangle-topic-${locale}`}>
                    <FormattedMessage id='homeCardCRMTopic' />
                  </span>
                </div>
                <div className='Rectangle-description'>
                  <span className={`Rectangle-description-${locale}`}>
                    <FormattedMessage id='homeCardCRMDescription-1' />
                    <br />
                    <FormattedMessage id='homeCardCRMDescription-2' />
                    <br />
                    <FormattedMessage id='homeCardCRMDescription-3' />
                  </span>
                </div>
              </div>
              <div className='Rectangle-17213'>
                <div className='Rectangle-icon'>
                  <img src={WorkforceImg} />
                </div>
                <div className='Rectangle-topic'>
                  <span className={`Rectangle-topic-${locale}`}>
                    <FormattedMessage id='homeCardWorkforceTopic' />
                  </span>
                </div>
                <div className='Rectangle-description'>
                  <span className={`Rectangle-description-${locale}`}>
                    <FormattedMessage id='homeCardWorkforceDescription-1' />
                    <br />
                    <FormattedMessage id='homeCardWorkforceDescription-2' />
                    <br />
                    <FormattedMessage id='homeCardWorkforceDescription-3' />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='row-hidden-a'>
            <Col className='ft-resp-v-col-get-started' lg={6}>
              <a className={`get-started-${locale}`} onClick={handleShowSendEmailModal}>
                <FormattedMessage id='homeGetStarted' />  &nbsp;<FontAwesomeIcon icon={faChevronRight} />
              </a>
            </Col>
          </Row>
          <Row className='row-hidden-button'>
            <Col className='ft-resp-v-col-get-started' lg={6}>
              <Button className={`get-start-btn-${locale}`} onClick={handleShowSendEmailMobileModal} >
                <FormattedMessage id='homeGetStarted' />
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </PageSettings.Consumer>
  );
}