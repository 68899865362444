import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { Container, Modal, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import '../../../re-design/assets/css/contact-policy-mobile.css'

export default (props) => {
  const modalProps = { ...props };
  _.unset(modalProps, ['locale']);
  const { locale, show, onHide } = props;
  const [statusRead, setStatusRead] = useState(true);
  const listInnerRef = useRef();

  useEffect(() => {
    setStatusRead(true);
  }, [show]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      console.log('sc1', scrollTop, clientHeight, scrollHeight)
      if (scrollTop + clientHeight >= (scrollHeight - 50)) {
        setStatusRead(false);
      }
    }
  };

  return (
    <Modal
      {...modalProps}
      className='policy-modal-mobile'
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <h1 className={`contact-policy-header-${locale}`}><FormattedMessage id='contactModalPolicyHeader' /></h1>
      <div
        className={`contact-policy-content`}
        onScroll={onScroll}
        ref={listInnerRef}
      >
        <Modal.Body>
          <Container fluid='xl'>
            <Row>
              <Col lg={12}>
                <p className={`contact-policy-content-1 contact-policy-content-1-${locale} t-a-c`}>
                  <FormattedMessage id='policyContent-1-1' />
                  <br />
                  <FormattedMessage id='policyContent-1-2' />
                  <br />
                  <FormattedMessage id='policyContent-1-3' />
                  <br />
                  <FormattedMessage id='policyContent-1-4' />
                </p>

                <p className={`contact-policy-content-2 contact-policy-content-2-${locale} mrg-t-50`}>
                  <FormattedMessage id='policyContent-2-1' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage id='policyContent-2-2' values={{ br: <br /> }} />
                </p>

                <p className={`contact-policy-content-2 contact-policy-content-2-${locale} mrg-t-100`}>
                  <FormattedMessage id='policyContent-3-1' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage id='policyContent-3-2' values={{ br: <br /> }} />
                </p>

                <p className={`contact-policy-content-2 contact-policy-content-2-${locale} mrg-t-100`}>
                  <FormattedMessage id='policyContent-4-1' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage id='policyContent-4-2' values={{ br: <br /> }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-4-2-1'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-4-2-2'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-4-2-3'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-4-2-4'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-4-2-5'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-4-2-6'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-4-2-7'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-4-2-8'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-4-2-9'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                {locale === 'en' ?
                  <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                    <FormattedMessage
                      id='policyContent-4-2-10'
                      values={{
                        bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                        br: <br />,
                        sp: <span className='mrg-l-28'></span>
                      }} />
                  </p> : null}

                {locale === 'en' ?
                  <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                    <FormattedMessage
                      id='policyContent-4-2-11'
                      values={{
                        bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                        br: <br />,
                        sp: <span className='mrg-l-28'></span>
                      }} />
                  </p> : null}

                <p className={`contact-policy-content-2 contact-policy-content-2-${locale} mrg-t-100`}>
                  <FormattedMessage id='policyContent-5-1' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage id='policyContent-5-2' values={{ br: <br /> }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-5-2-1'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-5-2-2'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-5-2-3'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                {locale === 'en' ?
                  <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                    <FormattedMessage
                      id='policyContent-5-2-4'
                      values={{
                        bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                        br: <br />,
                        sp: <span className='mrg-l-28'></span>
                      }} />
                  </p> : null}

                {locale === 'en' ?
                  <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-30`} >
                    <FormattedMessage id='policyContent-5-3' values={{ br: <br /> }} />
                  </p> : null}

                {locale === 'en' ?
                  <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                    <FormattedMessage
                      id='policyContent-5-3-1'
                      values={{
                        bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                        br: <br />,
                        sp: <span className='mrg-l-28'></span>
                      }} />
                  </p> : null}

                <p className={`contact-policy-content-2 contact-policy-content-2-${locale} mrg-t-100`}>
                  <FormattedMessage id='policyContent-6-1' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage id='policyContent-6-2' values={{ br: <br /> }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-1'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-2'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-3'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-4'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-5'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-6'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-7'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-8'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-9'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-10'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-11'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-12'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-13'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-14'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-15'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-6-2-16'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                {locale === 'en' ?
                  <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                    <FormattedMessage
                      id='policyContent-6-2-17'
                      values={{
                        bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                        br: <br />,
                        sp: <span className='mrg-l-28'></span>
                      }} />
                  </p> : null}

                <p className={`contact-policy-content-2 contact-policy-content-2-${locale} mrg-t-100`}>
                  <FormattedMessage id='policyContent-7-1' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage id='policyContent-7-2' values={{ br: <br /> }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage id='policyContent-7-3' values={{ br: <br /> }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage id='policyContent-7-4' values={{ br: <br /> }} />
                </p>

                {locale === 'en' ?
                  <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                    <FormattedMessage id='policyContent-7-5' values={{ br: <br /> }} />
                  </p> : null}

                {locale === 'en' ?
                  <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                    <FormattedMessage id='policyContent-7-6' values={{ br: <br /> }} />
                  </p> : null}

                {locale === 'en' ?
                  <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                    <FormattedMessage id='policyContent-7-7' values={{ br: <br /> }} />
                  </p> : null}

                <p className={`contact-policy-content-2 contact-policy-content-2-${locale} mrg-t-100`}>
                  <FormattedMessage id='policyContent-8-1' />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage id='policyContent-8-2' values={{ br: <br /> }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-8-2-1'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-8-2-2'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-8-2-3'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>

                <p className={`contact-policy-content-3 contact-policy-content-3-${locale} mrg-t-15`} >
                  <FormattedMessage
                    id='policyContent-8-2-4'
                    values={{
                      bullets: <FontAwesomeIcon className='bullets fa-2xs' icon={faCircle} />,
                      br: <br />,
                      sp: <span className='mrg-l-28'></span>
                    }} />
                </p>
              </Col>
            </Row>
            <Row className='mrg-t-30'>
              <Col className='t-a-c' lg={12}>
                <Button className={`policy-accept policy-accept-${locale}`} onClick={() => onHide(true)} disabled={statusRead}><FormattedMessage id='contactModalPolicyAccept' /></Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </div>
    </Modal >
  );
}