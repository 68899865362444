import React, { useState, useEffect, useRef, useContext } from 'react';
import { PageSettings } from '../config/page-settings';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-bootstrap';
import FacebookImg from '../assets/images/facebook.png';
import LineImg from '../assets/images/line.png';
import LinkInImg from '../assets/images/linkedIn.png';
import MailImg from '../assets/images/mail.png';
import WhatsAppImg from '../assets/images/whatsapp.png';
import _ from 'lodash';

export default () => {
  const app = useContext(PageSettings)
  const [visible, setVisible] = useState(true);
  const [visible1, setVisible1] = useState(true);
  const conRef = useRef();
  const conRef1 = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
      if (_.get(entries, '[0].isIntersecting')) {
        app.setBurgerMark('contact')
      }
    });
    observer.observe(conRef.current);
    return () => observer.unobserve(conRef.current);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible1(entry.isIntersecting));
      if (_.get(entries, '[0].isIntersecting')) {
        app.setBurgerMark('contact')
      }
    });
    observer.observe(conRef1.current);
    return () => observer.unobserve(conRef1.current);
  }, []);

  return (
    <PageSettings.Consumer>
      {({ locale, handleShowSendEmailModal, handleShowSendEmailMobileModal }) => (
        <div className='mrg-t-40'>
          <Container id='contact-us-container' className='ft-resp contact-us-container' fluid='xl'>
            <div ref={conRef} className={`fade-in-section ${visible ? 'is-visible' : ''}`}>
              <Row>
                <Col className='t-a-c' lg={12}>
                  <h2 className={`contact-us-container-header-${locale}`}>
                    <FormattedMessage id='contactUsHeader' />
                  </h2>
                  <hr className={`container-header-divider ${visible ? 'is-divider-visible' : ''}`} />
                </Col>
              </Row>
              <Row className='mrg-t-65'>
                <Col className='t-a-c' lg={12}>
                  <p className={`contact-us-container-description-${locale}`}>
                    <FormattedMessage id='contactUsDescription-1' />
                    <br />
                    <FormattedMessage id='contactUsDescription-2' />
                  </p>
                </Col>
              </Row>
              <Row className='mrg-t-65'>
                <Col className='contact-us-channel t-a-c' style={{ overflow: 'hidden', padding: 20 }} lg={12}>
                  <a href='https://www.facebook.com/rapidtech.th' target='_blank'>
                    <div className='Rectangle-18010'>
                      <img src={FacebookImg} />
                    </div>
                  </a>
                  <a href='https://www.linkedin.com/company/rapidtechth' target='_blank'>
                    <div className='Rectangle-18010'>
                      <img src={LinkInImg} />
                    </div>
                  </a>
                  <a onClick={handleShowSendEmailModal} className='hide-mail-contact-w'>
                    <div className='Rectangle-18010'>
                      <img src={MailImg} />
                    </div>
                  </a>
                  <a onClick={handleShowSendEmailMobileModal} className='hide-mail-contact-mb'>
                    <div className='Rectangle-18010'>
                      <img src={MailImg} />
                    </div>
                  </a>
                </Col>
              </Row>
            </div>
          </Container>
          <Container id='contact-us-container1' className='ft-resp-v contact-us-container' fluid='xl'>
            <div ref={conRef1} className={`fade-in-section ${visible1 ? 'is-visible' : ''}`}>
              <Row>
                <Col className='t-a-c' lg={12}>
                  <h2 className={`contact-us-container-header-${locale}`}>
                    <FormattedMessage id='contactUsHeader' />
                  </h2>
                  <hr className={`container-header-divider ${visible1 ? 'is-divider-visible' : ''}`} />
                </Col>
              </Row>
              <Row className='mrg-t-40'>
                <Col className='t-a-c' lg={12}>
                  <p className={`contact-us-container-description-${locale}`}>
                    <FormattedMessage id='contactUsDescription-1' />
                    <br />
                    <FormattedMessage id='contactUsDescription-2' />
                  </p>
                </Col>
              </Row>
              <Row className='mrg-t-40'>
                <Col className='contact-us-channel t-a-c mrg-t-35' lg={12}>
                <a href='https://www.facebook.com/rapidtech.th' target='_blank'>
                    <div className='Rectangle-18010'>
                      <img src={FacebookImg} />
                    </div>
                  </a>
                  <a href='https://www.linkedin.com/company/rapidtechth' target='_blank'>
                    <div className='Rectangle-18010'>
                      <img src={LinkInImg} />
                    </div>
                  </a>
                  <a onClick={handleShowSendEmailModal} className='hide-mail-contact-w'>
                    <div className='Rectangle-18010'>
                      <img src={MailImg} />
                    </div>
                  </a>
                  <a onClick={handleShowSendEmailMobileModal} className='hide-mail-contact-mb'>
                    <div className='Rectangle-18010'>
                      <img src={MailImg} />
                    </div>
                  </a>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )}
    </PageSettings.Consumer>
  );
}